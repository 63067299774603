<template>
  <div>
    <div
        class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">Notificações</span>
        <!--<span
          class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2"
        >
          23 new
        </span>-->
      </h4>

      <ul
          class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
          role="tablist"
      >
        <li class="nav-item" v-if="!salas || salas.length>0">
          <a
              v-on:click="setActiveTab"
              data-tab="0"
              class="nav-link active"
              data-toggle="tab"
              href="#"
              role="tab"
          >
            Salas
          </a>
        </li>
        <li class="nav-item" v-if="sala">
          <a
              v-on:click="setActiveTab"
              data-tab="1"
              class="nav-link"
              data-toggle="tab"
              href="#"
              role="tab"
          >
            Participantes
          </a>
        </li>
        <li class="nav-item" v-if="arquivos">
          <a
              v-on:click="setActiveTab"
              data-tab="2"
              class="nav-link"
              data-toggle="tab"
              href="#"
              role="tab"
          >
            Arquivos
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab v-if="!salas || salas.length>0" class="p-8">
        <perfect-scrollbar
            class="scroll pr-7 mr-n7"
            style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item, i) in salas">
            <!--begin::Item-->

            <div class="d-flex align-items-center mb-6" v-bind:key="i">
              <!--begin::Symbol-->
              <div
                  @click="vaiParaSala(item)"
                  class="symbol symbol-40 mr-5"
                  v-bind:class="`symbol-light-primary`"
              >
                <span class="symbol-label">
                  <span
                      class="svg-icon svg-icon-lg"
                      v-bind:class="{
                  	  'svg-icon-info':item.finalizada,
                  	  'svg-icon-primary':item.usuario_origem_id==usuario.id,
                  	  'svg-icon-danger':item.usuario_origem_id!=usuario.id,
                    }"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg v-if="item.usuario_origem_id==usuario.id"
                                :src="path + 'media/svg/icons/Navigation/Arrow-left.svg'"/>
                    <inline-svg v-else :src="path + 'media/svg/icons/Navigation/Arrow-right.svg'"/>
                    <!--end::Svg Icon-->
                  </span>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column font-weight-bold">
                <a
                    href="#"
                    @click="vaiParaSala(item)"
                    class="text-dark text-hover-primary mb-1 font-size-lg"
                >
                </a>
                <span class="text-muted"
                      v-if="item.usuario_origem_id==usuario.id">
                  Profissional: {{ item.nome_profissional_destino }}
                </span>
                <span class="text-muted"
                      v-else>
                  Profissional: {{ item.nome_usuario_origem }}
                </span>
                <!--
                                <span class="text-muted"
                                      v-else-if="">
                                  Paciente: {{ item.nome_paciente_destino }}
                                </span>
                                <span class="text-muted"
                                      v-else-if="item.nome_usuario_destino">
                                  Usuário: {{ item.nome_usuario_destino }}
                                </span>
                -->
                <span class="text-muted"
                      v-else="item.nome_usuario_destino">
                  Usuário: {{ item.nome_usuario_destino }}
                </span>
                <span class="text-muted">
                  <small>{{ item.created_at }}</small>
                </span>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab v-if="sala">
        <perfect-scrollbar
            class="navi navi-hover scroll my-4"
            style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item) in sala.participantes">
            <div class="row">
              <div class="col-sm-9 ml-5" v-if="item.paciente">
                <p>{{ item.paciente.no_usuario }} <small>({{ item.paciente.ddd }} {{ item.paciente.fone_celular }}) -
                  <!--Link:
                    <a href="#" @click="copia('lnk2')" ref="lnk2">
                      {{link + '/#/login?p='+item.id}}
                    </a>-->
                  <v-tooltip top z-index="2000">
                    <template v-slot:activator="{ on, attrs }">
                      <button type="button" @click="copia(link + '/#/login?p='+item.id)"
                              class="mdi mdi-18px mdi-content-copy theme--light ml-1" v-on="on"></button>
                    </template>
                    <span>Copia o Link</span>
                  </v-tooltip>

                  <v-tooltip top z-index="2000">
                    <template v-slot:activator="{ on, attrs }">
                      <button
                          @click='apiWhats(`${item.paciente.ddd}${item.paciente.fone_celular}`,`${link}/#/login?p=${item.id}`)'
                          target="_blank" class="v-icon mdi mdi-18px mdi-whatsapp ml-1 mb-2" v-on="on"></button>
                    </template>
                    <span>Abre Link no Whats</span>
                  </v-tooltip>
                </small>
                </p>
              </div>
              <div class="col-sm-9 ml-5" v-if="item.profissional">
                <p>{{ item.profissional.descricao }} <small>({{ item.profissional.ddd }}
                  {{ item.profissional.fone_celular }}) - Link: <a href="#" @click="copia('lnk1')"
                                                                   ref="lnk1">{{ link + '/#/login?p=' + item.id }}</a></small>
                </p>
              </div>
              <div class="col-sm-9 ml-5" v-if="item.usuario">
                <p>{{ item.usuario.nome }} <small> - Link: <a href="#" @click="copia('lnk3')"
                                                              ref="lnk3">{{ link + '/#/login?p=' + item.id }}</a></small>
                </p>
              </div>
              <div class="col-sm-2" style="margin-top: -18px;">

                <v-file-input show-size label="File input"
                              ref="file" @change="handleUploadToParticipante(item)"
                              multiple
                              :hide-input="true"
                              prepend-icon="ndi mdi-18px mdi-paperclip"
                              :loading="enviandoArquivo"
                              v-model="arquivoSelecionado"></v-file-input>

              </div>
            </div>
            <!--<a href="#" class="navi-item" v-bind:key="item.id">
              <div class="navi-link">
                <div class="navi-icon mr-2">
                  <i v-bind:class="item.icon"></i>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">
                    {{ item.paciente.no_usuario }}
                  </div>
                  <div class="text-muted">
                    {{ item.id_usuario_gms }}
                  </div>
                </div>
              </div>
            </a>-->
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab v-if="arquivos">
        <div class="d-flex flex-center text-left text-muted min-h-200px">
          <perfect-scrollbar
              class="scroll pr-7 mr-n7"
              style="max-height: 40vh; position: relative;"
          >
            <template v-for="(item, i) in arquivos">
              <!--begin::Item-->

              <div class="d-flex align-items-center mb-6" v-bind:key="i">
                <!--begin::Symbol-->
                <div
                    class="symbol symbol-40 mr-5"
                    v-bind:class="`symbol-light-primary`"
                >
                <span class="symbol-label" @click="downloadFile(item)">
                  <span
                      class="svg-icon svg-icon-lg"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg :src="path + 'media/svg/icons/Files/File.svg'"/>
                    <!--end::Svg Icon-->
                  </span>
                </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Text-->
                <div class="d-flex flex-column font-weight-bold">
                  <a
                      href="#"
                      @click="downloadFile(item)"
                      class="text-dark text-hover-primary mb-1 font-size-lg"
                  >
                    <!--{{ item.usuario_origem_id==usuario.id ? item.nome_profissional_destino : item.nome_usuario_origem }}-->
                  </a>
                  <span class="text-muted">
                   {{ item.nome_original }}
                  </span>
                  <span class="text-muted">
                    <v-chip v-if="item.nome_participante" color="red" text-color="white" class="ma-2"><small>{{ item.nome_participante }}</small></v-chip>
                  <!--<small>{{item.usuario.nome }}</small>-->
                 </span>
                </div>
                <!--end::Text-->
              </div>
              <!--end::Item-->
            </template>
          </perfect-scrollbar>

        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import {ADD_FILE} from "@/core/services/store/auth.module";
import {LINK} from '@/config';

export default {
  name: "KTDropdownNotification",
  data() {
    return {
      link: LINK,
      tabIndex: 0,
      path: process.env.BASE_URL,
      list1: [
        /*{
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "primary",
          svg: process.env.BASE_URL + "media/svg/icons/Home/Library.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "warning",
          svg: process.env.BASE_URL + "media/svg/icons/Communication/Write.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "success",
          svg:
            process.env.BASE_URL +
            "media/svg/icons/Communication/Group-chat.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "danger",
          svg: process.env.BASE_URL + "media/svg/icons/General/Attachment2.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "info",
          svg:
            process.env.BASE_URL +
            "media/svg/icons/Communication/Shield-user.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "info",
          svg:
            process.env.BASE_URL +
            "media/svg/icons/Communication/Mail-notification.svg"
        },
        {
          title: "Briviba SaaS",
          desc: "PHP, SQLite, Artisan CLIмм",
          color: "info",
          svg: process.env.BASE_URL + "media/svg/icons/Design/Bucket.svg"
        }*/
      ],
      list2: [
        /*{
          title: "New report has been received",
          desc: "23 hrs ago",
          icon: "flaticon2-line-chart text-success"
        },
        {
          title: "Finance report has been generated",
          desc: "25 hrs ago",
          icon: "flaticon2-paper-plane text-danger"
        },
        {
          title: "New order has been received",
          desc: "2 hrs ago",
          icon: "flaticon2-user flaticon2-line- text-success"
        },
        {
          title: "New customer is registered",
          desc: "3 hrs ago",
          icon: "flaticon2-pin text-primary"
        },
        {
          title: "Application has been approved",
          desc: "3 hrs ago",
          icon: "flaticon2-sms text-danger"
        },
        {
          title: "New file has been uploaded",
          desc: "5 hrs ago",
          icon: "flaticon2-pie-chart-3 text-warning"
        },
        {
          title: "New user feedback received",
          desc: "8 hrs ago",
          icon: "flaticon-pie-chart-1 text-info"
        },
        {
          title: "System reboot has been successfully completed",
          desc: "12 hrs ago",
          icon: "flaticon2-settings text-success"
        },
        {
          title: "New order has been placed",
          desc: "15 hrs ago",
          icon: "flaticon-safe-shield-protection text-primary"
        },
        {
          title: "Company meeting canceled",
          desc: "19 hrs ago",
          icon: "flaticon2-notification text-primary"
        },
        {
          title: "New report has been received",
          desc: "23 hrs ago",
          icon: "flaticon2-fax text-success"
        },
        {
          title: "Finance report has been generated",
          desc: "25 hrs ago",
          icon: "flaticon-download-1 text-danger"
        }*/
      ],
      enviandoArquivo: false,
      arquivoSelecionado: null,
    };
  },
  methods: {
    linkVencido(item) {
      return new Date(item.validade_sala) > new Date();
    },
    copia(link) {
      //alert(el);
      var dummy = document.createElement('input'),
          text = link;
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      this.$bvToast.toast('O Link foi copiado para a área de tansferência.', {
        title: 'Link Copiado',
        variant: 'success',
        toaster: 'b-toaster-top-right',
        solid: true
      });

    },
    apiWhats(telefone, texto) {
      //alert(window.encodeURIComponent(texto));
      var url = 'https://api.whatsapp.com/send?phone=+55' + telefone + '&text='
      var encodeLink = window.encodeURIComponent(texto);
      var destino = url + encodeLink;
      window.open(destino, '_blank');
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    handleUploadToParticipante(part) {
      //console.log(part);
      this.enviandoArquivo = true;
      let formData = new FormData();
      formData.append('arquivo', this.arquivoSelecionado[0]);
      formData.append('sala', this.sala.id);
      formData.append('participanteId', part.id);
      ApiService.setHeader();
      ApiService.setHeaderFileSend();
      ApiService.post(`files`, formData)
          .then(ret => {
            //console.log(ret);
            this.$store.dispatch(ADD_FILE, ret.data.image);
            this.enviandoArquivo = false;
          })
          .catch(e => {
            console.log(e);
          })
          .finally(() => {
            console.log('finalizou');
          });
    },
    downloadFile(item) {
      //console.log(item);
      this.$http.post(`files/download`, {
        id: item.id
      }, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }
      }).then((response) => {

        let blob = new Blob([response.data], {type: 'application/pdf'});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = item.nome_original;
        link.click();


        //console.log(response)
        //this.download(response.data,item.nome_original,item.mime_type)

        /*const blob = new Blob([response.data], {type: "image/pdf"})
        let a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'myFile.pdf'; // gives it a name via an a tag
        a.click();
        window.URL.revokeObjectURL(url);*/


        /*let blob = new Blob([response.data], {type: "octet/stream"} ),
          url = window.URL.createObjectURL(blob)

        window.open(url); // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions*/
      })
    },
    download(data, filename, mime, bom) {

      var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
      var blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 200)
      }

    },
    vaiParaSala(item) {
      console.log(item);
      //this.$emit('novoTeste', {id: 1});
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    salas() {
      return this.$store.getters.getSalas;
    },
    sala() {
      //console.log('sldkls');
      //console.log(this.$store.getters.getSala);
      return this.$store.getters.getSala;
    },
    usuario() {
      return this.$store.getters.currentUser;
    },
    arquivos() {
      return this.$store.getters.getArquivos;
    }
  }
};
</script>
